import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import { Seo } from "../../components/seo"
import Subscribe from "../../components/subscribe"
import Footer from "../../components/footer"

const EpisodePage = () => (
    <Layout>
        <div className="episode-detail-wrapper">
            <header>
                <StaticImage
                    src="../../images/black-christmas-poster.jpeg"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="A poster for the 1974 christmas horror movie, Black Christmas"
                    className="img-fluid"
                />
            </header>
            <div className="episode-detail-body container">
                <div className="row episode-detail-title text-center py-5">
                    <h1>Episode 29</h1>
                    <h2>Black Christmas</h2>
                    <h3>December 25, 2023</h3>
                </div>
                <div className="row episode-detail-plot boxed-in">
                    <div class="indent-this py-5">
                        <p><b>Director:</b> Bob Clark // <b>Starring:</b> Margot Kidder &amp; Olivia Hussey</p>
                        <div className="player">
                            <iframe src="https://podcasters.spotify.com/pod/show/bring-me-the-axe/embed/episodes/30-Black-Christmas-e2dk4m5/a-aap9l70" height="102px" width="400px" frameborder="0" scrolling="no" title="Bring Me The Axe episode 30, Black Christmas"></iframe>
                        </div>
                        <p><Link to="/transcripts/black-christmas">Read the episode transcript</Link></p>
                        <p>Bring Me the Axe is a comedy podcast celebrating the best (and worst) horror from a time when the video store ruled the night. Every other week, brothers Bryan and Dave White (and the occasional guest) heed the call of nostalgia and evaluate the classic 70s and 80s horror movies they loved in their childhood to determine whether the movies are still relevant today or should be allowed to fade into obscurity.</p>
                    </div>
                </div>
                <div className="row">
                    <Subscribe />
                </div>
                <div className="row previous-next indent-this">
                    <ul>
                        <li><Link to="/episodes/new-years-evil">Next Episode</Link></li>
                        <li><Link to="/episodes/2023-roundup">Previous Episode</Link></li>
                    </ul>
                </div>
                <Footer />
            </div>
        </div>
    </Layout>
)

export default EpisodePage

export const Head = () => (
    <Seo />
)